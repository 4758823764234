<template>
  <CContainer class="mt-5">
    <CRow
      v-if="isLoggingOut"
      class="justify-content-center"
    >
      <CCol>
        <CElementCover
          :opacity="0.8"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
          />
        </CElementCover>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import AuthService from '@/services/auth-service';

export default {
  name: 'Logout',

  data() {
    return {
      isLoggingOut: false,
    };
  },

  created() {
    try {
      this.isLoggingOut = true;

      AuthService.logout();

      this.$router.push({ name: 'login' });
    } catch (e) {
      //
    } finally {
      this.isLoggingOut = false;
    }
  },
};
</script>

<style scoped>

</style>
